import React from "react"
import NoIndex from "../components/no-index"

const LoginFailed = () => {

  function closeWindow() {
    if (typeof window !== `undefined`) {
      window.close()
    }
  }

  // useEffect(() => {
  //   sendMessage()
  // })

  return <div>
    <NoIndex/>
    Login Failed: An error occured during login.
    <div><button type="button" onClick={closeWindow}>Close Window</button></div>
  </div>
}

export default LoginFailed 
